import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import changeDebugMode from "../../redux/changeDebugMode";
import Condition from "./Condition";
import { DebugUser } from "../../redux/debugUserReduser";
import changeDebugUser from "../../redux/changeDebugUser";

export default function DebugInvitationEntryPage(): JSX.Element {
    const dispatch = useDispatch();

    const handlerSelectDebugMode = (mode: number) => {
        changeDebugMode (dispatch) (mode);
      }
    
    const [showStatusRequest, SetShowStatusRequest] = useState(false);
    const [[success, message], SetStatusRequest] = useState([true, ""]);
    const [showPassword, SetShowPassword] = useState(false);

    const [newPassword, SetNewPasswords] = useState("");
    const [doubleNewPassword, SetDoubleNewPasswords] = useState("");

    const handlerSend = async (event: any): Promise<void> => {
        event.preventDefault();
    
        const request = {
          "login": event.target.nicInput.value,
          "password": event.target.oldPasswordInput.value,
          "action": "CHANGE_PASSWORD",
          };
        
        const debUser: DebugUser = {
            debUserLogin: event.target.nicInput.value,
            debUserNewPassword: event.target.newPasswordInput.value,
            debUserIsAuthenticated: false
        }
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(request),
          };
    
        fetch('https://mitrapro.tech/deb_authorization/change_password/', requestOptions)
          .then((response) => {
            event.target.nicInput.value = "";
            event.target.oldPasswordInput.value = "";
            event.target.newPasswordInput.value = "";
            event.target.doublePasswordInput.value = "";
            return response.json();
          })
          .then((data) => {
            /*console.log(data);*/
            SetStatusRequest([data["success"], data["message"]]);
            if (data["success"]) {
                changeDebugUser (dispatch) (debUser);
                handlerSelectDebugMode(5);
            }
            SetShowStatusRequest(true);
          })
          .catch(() => {
            SetStatusRequest([false, "Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет."]);
          });
    }
  
    const latSymbol: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const latLowSymbol: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".toLowerCase();
    const numSymbol: string = "0123456789";
    const specSymbol: string = "!@#$%&*+-=_?";
    const allowedSymbols = latSymbol + latLowSymbol + numSymbol + specSymbol;
    
    function handlerInputSymbolNewPassword(event: any): void {
        SetNewPasswords(event.target.value);
        SetShowStatusRequest(false);
    }

    function handlerInputSymbolDoubleNewPassword(event: any): void {
        SetDoubleNewPasswords(event.target.value);
        SetShowStatusRequest(false);
    }

    function ConditionCheck1(): boolean {
        return (newPassword.length >= 12 && newPassword.length <= 32);
    }

    function ConditionCheck2(): boolean {
        for (let i=0; i<newPassword.length; i++ ) {
            if (latSymbol.includes(newPassword[i],0)) {
                return true;
            }
        }
        return false;
    }

    function ConditionCheck3(): boolean {
        for (let i=0; i<newPassword.length; i++ ) {
            if (latLowSymbol.includes(newPassword[i],0)) {
                return true;
            }
        }
        return false;
    }

    function ConditionCheck4(): boolean {
        for (let i=0; i<newPassword.length; i++ ) {
            if (numSymbol.includes(newPassword[i],0)) {
                return true;
            }
        }
        return false;
    }

    function ConditionCheck5(): boolean {
        for (let i=0; i<newPassword.length; i++ ) {
            if (specSymbol.includes(newPassword[i],0)) {
                return true;
            }
        }
        return false;
    }

    function ConditionCheck6(): boolean {
        if (newPassword.length === 0) return false;
        for (let i=0; i<newPassword.length; i++ ) {
            if ( !allowedSymbols.includes(newPassword[i],0) ) {
                return false;
            }
        }
        return true;
    }

    function ConditionCheck7(): boolean {
        if (newPassword === doubleNewPassword) {
            return true;
        }  else {
            return false;
        }  
    }

    function GeneralCheck(): boolean {
        return ConditionCheck1() && ConditionCheck2() &&
                ConditionCheck3() && ConditionCheck4() &&
                ConditionCheck5() && ConditionCheck6() && 
                ConditionCheck7();
    }

    const conditionArr = [
        {
            text: "1) Длина пароля от 12 до 32 символов;",
            func: ConditionCheck1()
        },
        {
            text: "2) Обязательно должны быть большие латинские буквы;",
            func: ConditionCheck2()
        },
        {
            text: "3) Обязательно должны быть маленькие латинские буквы;",
            func: ConditionCheck3()
        },
        {
            text: "4) Обязательно должны быть цифры;",
            func: ConditionCheck4()
        },
        {
            text: "5) Обязательно должны быть символы: '!@#$%&*+-=_?';",
            func: ConditionCheck5()
        },
        {
            text: "6) Не должно быть иных символов, кроме как указанных в пунктах 1-5;",
            func: ConditionCheck6()
        },
        {
            text: "7) Новый пароль введённый первоначально должен совпадать с повторно введённым новым паролем.",
            func: ConditionCheck7()
        },
    ]

    const loginFormJSX: JSX.Element = 
    <>
        <div className="App">
            <form className="debug_login_box" onSubmit={handlerSend} >
                <h3 className="">Смена пароля. Только для сотрудников.</h3>
                <div className="debug_login_field_box">
                    <label className="label_fld" htmlFor="nicInput">Логин:</label>
                    <input
                        onInput={() => SetShowStatusRequest(false)}
                        className="debug_login_field"
                        placeholder="Логин"
                        required
                        type="text"
                        name="nic" 
                        maxLength={32}
                        id="nicInput"
                    />
                </div>
    
                <div className="debug_login_field_box">
                    <label className="label_fld" htmlFor="oldPasswordInput">Действующий пароль:</label>
                    <input
                        onInput={() => SetShowStatusRequest(false)}
                        className="debug_login_field"
                        placeholder="Действующий пароль"
                        required
                        type={(showPassword) ? "text" : "password" }
                        name="oldPassword" 
                        id="oldPasswordInput"
                        maxLength={32}
                    />
                </div>

                <div className="debug_login_field_box">
                    <label className="label_fld" htmlFor="newPasswordInput">Новый пароль:</label>
                    <input
                        className="debug_login_field"
                        onInput={(event) => handlerInputSymbolNewPassword(event)}
                        placeholder="Новый пароль"
                        required
                        type={(showPassword) ? "text" : "password" }
                        name="newPassword" 
                        id="newPasswordInput"
                        maxLength={32}
                    />
                </div>

                <Condition text={conditionArr[0].text} status={ConditionCheck1()} />
                <Condition text={conditionArr[1].text} status={ConditionCheck2()} />
                <Condition text={conditionArr[2].text} status={ConditionCheck3()} />
                <Condition text={conditionArr[3].text} status={ConditionCheck4()} />
                <Condition text={conditionArr[4].text} status={ConditionCheck5()} />
                <Condition text={conditionArr[5].text} status={ConditionCheck6()} />
                <Condition text={conditionArr[6].text} status={ConditionCheck7()} />

                <div className="debug_login_field_box">
                    <label className="label_fld" htmlFor="doublePasswordInput">Повторите ввод нового пароля:</label>
                    <input
                        onInput={(event) => handlerInputSymbolDoubleNewPassword(event)}
                        className="debug_login_field"
                        placeholder="Еще раз пароль"
                        required
                        type={(showPassword) ? "text" : "password" }
                        name="doublePassword" 
                        id="doublePasswordInput"
                        maxLength={32}
                    />
                </div>
            
                <div className="debug_login_field_box">
                    <input
                        disabled={!GeneralCheck()}
                        type="submit" 
                        className="debug_login_field"
                        name="submit_btn" 
                    />
                </div>
            </form>
            <button onClick={() => SetShowPassword(!showPassword)}>
              {(showPassword) ? "Скрыть пароль" : "Показать пароль" }
            </button>
        </div>
    </>

    const handlerGoBack = () => {
        handlerSelectDebugMode(0);
    }
        
    return (
        <>
            <button className="goback_button" onClick={handlerGoBack} >Назад</button>
            { loginFormJSX }
            { (showStatusRequest) ? message : "" }
        </>
    );
}
    