import './App.css';
import Geography from './components/Geography';
import Language from './components/Language';
import Login from './components/Login';
import Logo from './components/Logo';
import Section from './components/SectionSelector';
import { useSelector } from 'react-redux';
import ContentPage from './components/ContentPage';

export type User = {
  userID: number;
  userNic: string;
  userToken: string;
  userIsAuthenticated: boolean;
}

function AppDev() {
  const { authenticatedMode } = useSelector((state: any) => state.authenticatedMode);
  const { debugMode } = useSelector((state: any) => state.debugMode);

  const { userNic, userIsAuthenticated } = useSelector((state: any) => state.user);
  
  return (
    <>
    <div className='screen'>
      <div className='status_bar'>
        <Logo />
        <Section />
        <Geography />
        <Language />
        {(authenticatedMode) ? <></> : <Login />}
      </div>

      <ContentPage />

    </div>
    </>
  );
}

export default AppDev;
