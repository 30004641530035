import { useSelector } from "react-redux";
import { MessageTechSupport, TechSupportContact } from "./TechSupport";
import { messageTechSupport } from "../DebugData/DebugTechSupport";

interface TicketCard {
    tickerCard: TechSupportContact;
}

export default function TechSupportDialog(props:TicketCard): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);
    
    const buttonTransmitJSX:JSX.Element =
        <>
            <div><button className="favorite_button">Отправить</button></div>
        </>

    const buttonAttachJSX:JSX.Element =
        <>
            <div><button className="favorite_button">Прикрепить файлы</button></div>
        </>

    const dialogMessage = messageTechSupport.filter((item) => item.techSupportContactID === props.tickerCard.id);
    
    const messageListJSX = dialogMessage.map((item:MessageTechSupport) => 
        <div key={item.id} className="ticket_dialog">
            <div className={(item.isTransmit) ? "transmit_message_box" : "recive_message_box"}>
                <div>{ item.text }</div>
            </div>
        </div>
    );

    return (
        <>
            <div>
                <div className="ticker_header">
                    <div>Тикет № {props.tickerCard.ticket}</div>
                    <div>Тема: {props.tickerCard.subject}</div>
                </div>
                <div className="ticker_card_text">{props.tickerCard.text}</div>
                {messageListJSX}
                <div className="write_message">
                    <input
                        className="message_text"
                        placeholder="Напишите сообщение"
                        required
                        type="text"
                        name="messageText" 
                        id="messageTextInput"
                    />
                    {buttonTransmitJSX}
                    {buttonAttachJSX}
                </div>
            </div>
        </>
    );
}