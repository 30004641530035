import PetShortCard from "./PetShortCard";
import Advertiser from "./Advertiser";
import { useState } from "react";
import PetFullCardPage from "./PetFullCardPage";
import { useSelector } from "react-redux";
import { cardList } from "./DebugData/DebugCardList";
import { advertiserList } from "./DebugData/DebugAdvertiserList";

export type Photo = {
    id: number,
    src: string,
    alt: string,
}

export interface Card {
        id: number;
        name: string;
        breed: string;
        dob: string; 
        price: string;
        photos: Array<Photo>;
        description: string;
        telNumber: string;
    }

export interface Advert {
    id: number;
    name: string;
    url: string;
}

interface ItemShowObject {
        kind: string;
        id: number;
    }

export default function HomePage(): JSX.Element {
    function getAdvertiserCard(id: number): Advert {
        let item: Advert = { id: 0, name: "", url: "",};
        for (let i=0; i<advertiserList.length; i++) {
            if (id===advertiserList[i].id) {
                return advertiserList[i];
            }
        }
        return item;
    } 
    
    function getAdvertiserURL(id: number): string {
        for (let i=0; i<advertiserList.length; i++) {
            if (id===advertiserList[i].id) {
                return advertiserList[i].url;
            }
        }
        return "";
    }

    function getPetCard(id: number): Card {
        let item: Card = { id: 0, name: "", breed: "", dob: "", price: "", photos: [{id: 0, src: "", alt: "",}], description: "", telNumber: ""};
        for (let i=0; i<cardList.length; i++) {
            if (id===cardList[i].id) {
                return cardList[i];
            }
        }
        return item;
    }

    function ruleAddAdv (i: number): Boolean {
        const freq = 5;
        if (i%freq === 0) {
            return true;
        }
        return false;
    }

    function getMixList(cardList: Array<Card>, advertList: Array<Advert>): Array<ItemShowObject> {
        let rizultList: Array<ItemShowObject> = [];
        let i: number = 0;
        let j: number = 0;
        let item: ItemShowObject;
        while (i<cardList.length) {
            item = { kind: "c", id: cardList[i].id,}
            rizultList.push(item);
            if (ruleAddAdv(i)) {
                if (j<advertList.length) {
                    item = { kind: "a", id: advertList[j].id,}
                    rizultList.push(item);
                    j++;
                }
            }
            i++;
        }

        return rizultList;
    }

    const {sectionID} = useSelector((state: any) => state.section);

    const mixList: ItemShowObject[] = getMixList(cardList, advertiserList);
    
    const [showPetFullCardID, SetPetFullCardID] = useState(0);
    const [showChangeFilter, SetShowChangeFilter] = useState(false);

    const handlerClick = (id: number, kind: string ) => {
        if (kind === "c") {
            SetPetFullCardID(id);
        } 
        if (kind === "a") {
            let url = getAdvertiserURL(id);
            window.open(url, '_blank');
        }
    }

    const showListViewJSX = mixList.map((item: ItemShowObject) =>
        <div key={item.kind + String(item.id)} onClick={() => handlerClick(item.id, item.kind)}>
            {(item.kind === "c") ? <PetShortCard petCard={getPetCard(item.id)} isFavorite={true}/> : <Advertiser advertiserCard={getAdvertiserCard(item.id)} />}
        </div>
    );

    const handlerClickBack = () => {
        SetPetFullCardID(0);
    }

    const handlerApply = () => {
        SetShowChangeFilter(false);
    }

    const filterBlock: JSX.Element =
        <>
            <form className="debug_login_box" onSubmit={handlerApply} >
                <h3 className="">Параметры отбора:</h3>
                
                <div className="debug_login_field_box">
                    <label className="label_fld" htmlFor="breedInput">Порода</label>
                    <select id="breedInput" name="breed">
                        <option value="all">Любая</option>
                        <option value="drx">Девон рекс</option>
                        <option value="crx">Корниш рекс</option>
                        <option value="spx">Канадский сфинкс</option>
                    </select>
                </div>
    
                <div className="debug_login_field_box">
                    <label className="label_fld" htmlFor="genderInput">Пол</label>
                    <select id="genderInput" name="gender">
                        <option value="all">Любой</option>
                        <option value="male">{"Кот(male)"}</option>
                        <option value="crx">{"Кошка(female)"}</option>
                    </select>
                </div>

                <label className="label_fld" htmlFor="ageMinInput">Возраст от</label>
                <input
                    type="number" 
                    name="ageMin" 
                    id="ageMinInput"
                    className="input_text_fld"
                />

                <select id="ageMinUnitInput" name="geMinUnit">
                    <option value="month">{"мес."}</option>
                    <option value="year">{"лет(года)"}</option>
                </select>

                <label className="label_fld" htmlFor="ageMaxInput">До</label>
                <input
                    type="number" 
                    name="ageMax" 
                    id="ageMaxInput"
                    className="input_text_fld"
                />

                <select id="ageMaxUnitInput" name="geMaxUnit">
                    <option value="month">{"мес."}</option>
                    <option value="year">{"лет(года)"}</option>
                </select>

                <div>
                    <label className="label_fld" htmlFor="priceUnitInput">Цена</label>
                    <select id="priceUnitInput" name="priceUnit">
                        <option value="rub">{"руб."}</option>
                        <option value="usd">{"USD"}</option>
                        <option value="euro">{"eur"}</option>
                        <option value="cny">{"CNY"}</option>
                    </select>
                    <label className="label_fld" htmlFor="priceMinInput">от</label>
                    <input
                        type="number" 
                        name="priceMin" 
                        id="priceMinInput"
                        className="input_text_fld"
                    />
                    <label className="label_fld" htmlFor="priceMaxInput">до</label>
                    <input
                        type="number" 
                        name="priceMax" 
                        id="priceMaxInput"
                        className="input_text_fld"
                    />
                </div>
                <div>
                    <label className="label_fld" htmlFor="salerInput">Продавец</label>
                    <select id="salerInput" name="saler">
                        <option value="rub">{"Любой"}</option>
                        <option value="usd">{"Питомник"}</option>
                        <option value="euro">{"Big Eared Miracle"}</option>
                        <option value="cny">{"Forest Miracle"}</option>
                    </select>
                </div>

                <div className="debug_login_field_box">
                    <input 
                        type="submit" 
                        className="debug_login_field"
                        name="submit_btn" 
                        value="Применить" 
                    />
                    <button>Очистить всё</button>
                    <button>Отменить</button>
                </div>   
            </form>
        </>

    const filterSeting: JSX.Element = 
        <>
            <div>{"Порода: Девон рекс"}   х</div>
            <div>{"Пол: male(кот)"}   х</div>
        </>

    const showListJSX: JSX.Element = 
        <>
            <div className="filter_block">
                <button className="filter_button"
                    onClick={() => SetShowChangeFilter(!showChangeFilter)}>
                        { (showChangeFilter) ? "Скрыть фильтр" : "Изменить фильтр" }
                </button>
                { (showChangeFilter) ? filterBlock : filterSeting }
            </div>
            { showListViewJSX }
            <div className="pagination_block">
                { "< 1 2 3 ... >" }
            </div>
        </>

    const showPetCard: JSX.Element = 
        <>
            <div className="go_back" onClick={() => handlerClickBack()}>
                Назад
            </div>
            <PetFullCardPage petCard={getPetCard(showPetFullCardID)} />
        </>
    return (
        <>
        {sectionID===1 ?       
            <div className="home_page">
                { (showPetFullCardID === 0) ? showListJSX : showPetCard }
            </div> : <></>
        }
        </>
    );
}