import { Card } from "../HomePage";

export const cardList:Card[] = [
    {
        id: 1, 
        name: "Big Eared Miracle Bosphor", 
        breed: "Devon Rex", 
        dob: "30 апреля 2024 г.", 
        price: "40 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph01.jpeg",
                alt: "ph01",
            },
            {
                id: 1,
                src: "photo/ph02.jpeg",
                alt: "ph02",
            },
            {
                id: 2,
                src: "photo/ph03.jpeg",
                alt: "ph03",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-01",
    },
    {
        id: 2, 
        name: "Big Eared Miracle Sally", 
        breed: "Devon Rex", 
        dob: "01 мая 2024 г.", 
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph06.jpeg",
                alt: "ph06",
            },
            {
                id: 1,
                src: "photo/ph20.jpeg",
                alt: "ph20",
            },
            {
                id: 2,
                src: "photo/ph21.jpeg",
                alt: "ph21",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-02",
    },
    {
        id: 3, 
        name: "Big Eared Miracle Xsavier", 
        breed: "Devon Rex", 
        dob: "01 марта 2023 г.", 
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph07.jpeg",
                alt: "ph07",
            },
            {
                id: 1,
                src: "photo/ph22.jpeg",
                alt: "ph22",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-03",
    },
    {
        id: 4, 
        name: "Big Eared Miracle Zlata", 
        breed: "Devon Rex", 
        dob: "20 марта 2024 г.", 
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph09.jpeg",
                alt: "ph09",
            },
            {
                id: 1,
                src: "photo/ph23.jpeg",
                alt: "ph23",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-04",
    },
    {
        id: 5, 
        name: "Big Eared Miracle Zara", 
        breed: "Devon Rex", 
        dob: "20 марта 2024 г.", 
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph10.jpeg",
                alt: "ph10",
            },
            {
                id: 1,
                src: "photo/ph11.jpeg",
                alt: "ph11",
            },
            {
                id: 2,
                src: "photo/ph12.jpeg",
                alt: "ph12",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-05",
    },
    {
        id: 6, 
        name: "Big Eared Miracle Semen", 
        breed: "Devon Rex", 
        dob: "01 мая 2024 г.", 
        price: "50 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph13.jpeg",
                alt: "ph13",
            },
            {
                id: 1,
                src: "photo/ph24.jpeg",
                alt: "ph24",
            },
            {
                id: 2,
                src: "photo/ph25.jpeg",
                alt: "ph25",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-06",
    },
    {
        id: 7, 
        name: "Forest Miracle Uma", 
        breed: "Devon Rex", 
        dob: "23 июня 2019 г.", 
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph14.jpeg",
                alt: "ph14",
            },
            {
                id: 1,
                src: "photo/ph15.jpeg",
                alt: "ph15",
            },
            {
                id: 2,
                src: "photo/ph26.jpeg",
                alt: "ph26",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-07",
    },
    {
        id: 8, 
        name: "Forest Miracle Shanel", 
        breed: "Devon Rex", 
        dob: "13 июля 2022 г.", 
        price: "В добрые руки",
        photos: [
            {
                id: 0,
                src: "photo/ph16.jpeg",
                alt: "ph16",
            },
            {
                id: 1,
                src: "photo/ph17.jpeg",
                alt: "ph17",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-08",
    },
    {
        id: 9, 
        name: "Big Eared Miracle Forbs", 
        breed: "Devon Rex", 
        dob: "05 мая 2024 г.", 
        price: "55 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph18.jpeg",
                alt: "ph18",
            },
            {
                id: 1,
                src: "photo/ph19.jpeg",
                alt: "ph19",
            },
            {
                id: 2,
                src: "photo/ph28.jpeg",
                alt: "ph28",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-09",
    },
    {
        id: 10, 
        name: "Big Eared Miracle Dardanella", 
        breed: "Devon Rex", 
        dob: "05 мая 2024 г.", 
        price: "60 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph27.jpeg",
                alt: "ph27",
            },
            {
                id: 1,
                src: "photo/ph28.jpeg",
                alt: "ph28",
            },
            {
                id: 2,
                src: "photo/ph29.jpeg",
                alt: "ph29",
            },
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-10",
    },
    {
        id: 11, 
        name: "ch.WCF Big Eared Miracle Radja", 
        breed: "Devon Rex", 
        dob: "25 апреля 2022 г.", 
        price: "30 000 руб.",
        photos: [
            {
                id: 0,
                src: "photo/ph32.jpeg",
                alt: "ph32",
            },
            {
                id: 1,
                src: "photo/ph30.jpeg",
                alt: "ph30",
            },
            {
                id: 2,
                src: "photo/ph31.jpeg",
                alt: "ph31",
            }
        ],
        description: "Весёлый, забавный, милый",
        telNumber: "+7-918-322-22-11",
    },
  ]
