import { SET_DEBUG_MODE } from "./actions"

const changeDebugMode = (dispatch: any) => (mode: number) => {
  const action = {
    type: SET_DEBUG_MODE,
    payload: mode,
  }
  
  dispatch(action);
}

export default changeDebugMode;