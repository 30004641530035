import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import changeSection from "../redux/changeSection";


export type Section = {
    id: number,
    name: string,
  }
  
export default function SectionSelector(): JSX.Element {
    const section: Section[] = [
        { id: 1, name: "Кошки",},
        { id: 2, name: "Собаки",},
        { id: 3, name: "Лошади",},
        { id: 4, name: "Рептилии",},
        { id: 5, name: "Птицы",},
        { id: 6, name: "Рыбки",},
      ];
    
    const dispatch = useDispatch();
    const {sectionID} = useSelector((state: any) => state.section);
    
    const [sectionActive, SetSectionActive] = useState(0);
    const [showMenu, SetShowMenu] = useState(false);
    
    const handlerClickChangeSection = (id: number) => {
        changeSection (dispatch) (id);
    }

    const sectionMenu = section.map((item: Section) =>
        <div key={item.id} onClick={() => handlerClickChangeSection(item.id)}>
            {item.name}
        </div>
    );

    const selector: JSX.Element = 
        <>
            <div className="section_selector">
                {(showMenu) ? sectionMenu : <></>}
            </div>
        </>
    
    return (
        <>
        <div className="section" onClick={() => SetShowMenu(!showMenu)}>
            {(sectionID===0) ? "Выбор секции" : section[sectionID-1].name}
            {selector}
        </div>
        </>
    );
}

/*

        <div key={item.id} onClick={() => SetSectionActive(item.id)}>
            {item.name}
        </div>
*/