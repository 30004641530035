import { SET_AUTHENTICATED_MODE } from "./actions"

const setAuthenticatedMode = (dispatch: any) => (status: boolean) => {
  const action = {
    type: SET_AUTHENTICATED_MODE,
    payload: status,
  }
  
  dispatch(action);
}


export default setAuthenticatedMode;