import { useState } from "react";
import { messageTechSupport, ticketList } from "../DebugData/DebugTechSupport";
import TechSupportDialog from "./TechSupportDialog";

export type TechSupportContact = {
    id: number;
    ticket: string;
    nicName: string;
    datetime: string;
    subject: string;
    status:string;
    text: string;
    files: Array<string>;
}

export type MessageTechSupport = {
    id: number;
    techSupportContactID: number;
    isTransmit: boolean;
    text: string;
}

export default function TechSupport(): JSX.Element {
    function getTechSupportContact(id: number): TechSupportContact {
        let item:TechSupportContact = {
            id: 0,
            ticket: "",
            nicName: "",
            datetime: '',
            subject: "",
            status: "",
            text: "",
            files: [],
        }
        for (let i=0; i<ticketList.length; i++) {
            if (id===ticketList[i].id) {
                return ticketList[i];
            }
        }
        return item;
    }

    const [showTicketID, SetTicketID] = useState(0);

    const handlerClickTicket = (id:number) => {
        SetTicketID(id);
    }

    const ticketListJSX = ticketList.map((item:TechSupportContact) => 
        <div key={item.id} 
            className="ticket_item"
            onClick={() => handlerClickTicket(item.id)}>
            
                <div>Тема: { item.subject }</div>
                <div>Тикет № { item.ticket }</div>
                <div>Дата и время обращения: { item.datetime }</div>
                <div>Статус: { item.status }</div>
            
        </div>
    );

    const viewListJSX:JSX.Element = 
        <>
            <h2>Обращения в техподдержку</h2>
            <div><button className="favorite_button">Открытые</button></div>
            <div><button className="favorite_button">Закрытые</button></div>
            <div><button className="favorite_button">Все</button></div>
            {ticketListJSX}
        </>
    
    const viewDialogJSX:JSX.Element =
        <>
            <div className="go_back" onClick={() => SetTicketID(0)}>
                Назад
            </div>
                <TechSupportDialog tickerCard={ getTechSupportContact(showTicketID) } />
        </>

    return (
        <>      
            { (showTicketID===0) ? viewListJSX : viewDialogJSX }
        </>
    );
}