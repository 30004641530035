import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import setAuthenticatedMode from "../redux/authMode";
import { setUserLogOut } from "../redux/authUser";
import { NavLink } from "react-router-dom";

export type Menu = {
    id: number,
    name: string,
    url: string,
  }

export default function Login(): JSX.Element {
    const dispatch = useDispatch();
    const { userNic, userIsAuthenticated } = useSelector((state: any) => state.user);

    const loginMenuArr: Menu[] = [
        { id: 1, url: "/tech_support", name: "Обращения в техподдержку",},
        { id: 2, url: "/nopage", name: "Сообщения",},
        { id: 3, url: "/nopage", name: "Мои питомцы",},
        { id: 4, url: "/my_announcements", name: "Мои объявления",},
        { id: 5, url: "/my_nursery", name: "Мои питомники",},
        { id: 6, url: "/nopage", name: "Финансы",},
        { id: 7, url: "/nopage", name: "Избранное",},
        { id: 8, url: "/nopage", name: "Настройки",},
        { id: 9, url: "", name: "Выход",},
      ];

    const [showLoginMenu, SetShowLoginMenu] = useState(false);

    const handlerClickLoginMenuItem = (id: number) => {
        if (id===9) { 
            setUserLogOut (dispatch) () 
        } 
        SetShowLoginMenu(false);
    }

    const handlerClickComeIn = () => {
        setAuthenticatedMode (dispatch) (true);
    }

    const loginMenu = loginMenuArr.map((item: Menu) =>
        <div className="login_menu_item">
            <NavLink key={item.id} 
                className="menu_item" 
                onClick={() => handlerClickLoginMenuItem(item.id)}
                to={item.url}>
            
                {item.name}

            </NavLink>
        </div>
    );

    const nicNameJSX: JSX.Element = 
        <>
            <div className="nic" onClick={() => SetShowLoginMenu(!showLoginMenu)}>
                {userNic}
            </div>
            <div className="login_menu">
                {(showLoginMenu) ? loginMenu : <></>}
            </div>
        </>

    const loginButtonJSX: JSX.Element = 
        <>
            <div>
                <button className="login_button"
                    onClick={handlerClickComeIn}>
                        Войти
                </button>
            </div>
        </>

    return (
        <>      
        <div className="login">
            { (userIsAuthenticated) ? nicNameJSX : loginButtonJSX }
        </div>
        </>
    );
}

/*
    const loginMenu = loginMenuArr.map((item:Menu) =>
        <div key={item.id} className="login_menu_item" onClick={() => handlerClickLoginMenuItem(item.id)}>
            {item.name}
        </div>
    );


else {
            const url:string = getURL(id);
            document.location.href = url;
        }
*/