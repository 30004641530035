import { useState } from 'react';
import './App.css';
import AppDev from './AppDev';
import DebugInvitationEntryPage from './components/DebugComponent/DebugInvitationEntryPage';
import DebugEntryPage from './components/DebugComponent/DebugEntryPage';
import { useDispatch, useSelector } from 'react-redux';
import changeDebugMode from './redux/changeDebugMode';
import ConfirmPage from './components/DebugComponent/ConfirmPage';

function App() {
  const dispatch = useDispatch();
  const { debugMode } = useSelector((state: any) => state.debugMode);
  
  const handlerSelectDebugMode = (mode: number) => {
    changeDebugMode (dispatch) (mode);
  }
  const invitationEntryButton: JSX.Element =
    <>
      <button className="debug_entry_button" onClick={() => handlerSelectDebugMode(1)}>3.</button>
    </>

  const entryButton: JSX.Element =
    <>
      <button className="debug_entry_button" onClick={() => handlerSelectDebugMode(2)}>2,</button>
    </>

  const emtyButton: JSX.Element =
    <>
      <button className="debug_entry_button">1,</button>
    </>

  const aboutProject: JSX.Element =
    <>
      <div className="App">
        <header className="App-header">
          <p>
            Проект <strong>petbreeder.pro</strong> - это площадка на которой любители домашних животных 
            смогут для себя найти и приобрести любимца у профессиональных заводчиков.
          </p>
           <p>
            Приглашаем к сотрудничеству рекламодателей.
          </p>
          <p>
            <a href="mailto:admin@petbreeder.pro" className="send_mail">admin@petbreeder.pro</a>
          </p>
          <p>+7 918 46 33 009</p>
          <p>+7 989 80 80 125</p>
          <p>Для неравнодушных желающих поддержать проект донатами 
            сообщаем банковские реквизиты для перевода денег:
          </p>
        
          <p>ООО "МИТРА"</p>
          <p>ИНН: 2361013428</p>
          <p>КПП: 231201001</p>
          <p>ОГРН: 1152361013034</p>
          <p>Расчётный счёт: 40702810030000042944</p>
          <p>Банк: КРАСНОДАРСКОЕ ОТДЕЛЕНИЕ N8619 ПАО СБЕРБАНК</p>
          <p>БИК: 040349602</p>
          <p>Корсчёт: 30101810100000000602</p>
          
          <div className='copyright_box'>
            <h6 className="copyright">&copy; 
              <a href="https://www.mitra123.pro/" target="_blank" rel="noopener noreferrer" className='creator_href'>
                {' ООО "Митра Про 123"'}
              </a>, 2024. Разработка web-приложений. Всё просто как {emtyButton} {entryButton} {invitationEntryButton}
            </h6>
          </div>
        </header>
      </div>
    </>

  return (
    <>
      {(debugMode === 0) ? aboutProject : 
        (debugMode === 1) ? <DebugInvitationEntryPage /> : 
          (debugMode === 2) ? <DebugEntryPage /> : 
            (debugMode === 3) ? <AppDev /> : <ConfirmPage /> }
    </>
  );
}

export default App;
