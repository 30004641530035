import { useSelector } from "react-redux";
import { Nursery } from "./MyNursery";

interface NurseryCard {
    nurseryCard: Nursery;
}

export default function NurseryEdit(props:NurseryCard): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);
    
    const buttonSaveJSX:JSX.Element =
        <>
            <div><button className="favorite_button">Сохранить</button></div>
        </>
    
    const buttonCancelJSX:JSX.Element =
        <>
            <div><button className="write_message_button">Отменить</button></div>
        </>

    return (
        <>
            <div className="nursery_edit">
                <img alt="логотип питомника" src={props.nurseryCard.logoNursery}/>
                <div className="nursery_edit_text">
                    <div>Наименование:{props.nurseryCard.name}</div>
                    <div>Федерация:{props.nurseryCard.federation}</div>
                    <div>Заводчик:{props.nurseryCard.breeder}</div>
                    <div>Адрес:{props.nurseryCard.adress}</div>
                    <div>Страна:{props.nurseryCard.country}</div>
                    <div>Клуб:{props.nurseryCard.club}</div>
                    <div>Дата регистрации питомника:{props.nurseryCard.registrationDate}</div>
                    <div>Регистрация действительна до:{props.nurseryCard.registrationDate}</div>
                </div>
                {buttonSaveJSX}
                {buttonCancelJSX}
            </div>
        </>
    );
}