import { SET_DEBUG_USER } from "./actions"
import { DebugUser } from "./debugUserReduser";

const changeDebugUser = (dispatch: any) => (debUser: DebugUser) => {
  const action = {
    type: SET_DEBUG_USER,
    payload: debUser,
  }
  
  dispatch(action);
}

export default changeDebugUser;