import { SET_SECTION } from "./actions"

const changeSection = (dispatch: any) => (num: number) => {
  const action = {
    type: SET_SECTION,
    payload: num,
  }
  
  dispatch(action);
}


export default changeSection;