import {
  combineReducers,
  compose,
  legacy_createStore
} from "redux";
import sectionReducer from "./sectionReduser";
import userReducer from "./userReduser";
import authenticatedModeReducer from "./authenticatedModeReduser";
import debugModeReducer from "./debugModeReduser";
import debugUserReduser from "./debugUserReduser";

/*const ReactReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();*/

export default function configureStore() {
  return legacy_createStore(
    combineReducers({
      section: sectionReducer,
      user: userReducer,
      authenticatedMode: authenticatedModeReducer,
      debugMode: debugModeReducer,
      debugUser: debugUserReduser,
    }),
    undefined,
    compose(
      /*ReactReduxDevTools,*/
    )
  );
}


