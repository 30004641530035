import { useDispatch } from "react-redux";
import setAuthenticatedMode from "../redux/authMode";
import { User } from "../AppDev";
import { setUserLogin } from "../redux/authUser";
import { useState } from "react";

export default function LoginPage(): JSX.Element {
    const dispatch = useDispatch();

    const debugUser:User = { 
        userID: 0,
        userNic: "",
        userToken: "",
        userIsAuthenticated: false,
        }

    const [registrationMode, SetRegistrationMode] = useState(false);
    const [[confirmationMode, userConfirmation], SetConfirmationMode] = useState([false, debugUser])
    
    const handlerConfirmation = () => {

        SetConfirmationMode([false, userConfirmation]);
        
        setUserLogin (dispatch) (userConfirmation);

        setAuthenticatedMode (dispatch) (false);
    }

    const handlerAutorization = async (event: any): Promise<void> => {
        event.preventDefault()
        
        const user: User = { 
            userID: 1,
            userNic: event.target.nicInput.value,
            userToken: "123",
            userIsAuthenticated: true,
            }
        
        SetConfirmationMode([true, user]);
      
        /*const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            };*/
      
        /* "http://5.35.91.24:8000/api/messages/" */
        /*fetch('https://mitrapro.tech/api/messages/', requestOptions)
            .then(() => {
              event.target.contentInput.value = "";
            })*/
        
       
    }

    const handlerGoBack = () => {
        if (confirmationMode) {
            SetConfirmationMode([false, userConfirmation]);
        } else {
            if (registrationMode) {
                SetRegistrationMode(false);
            } else {
                setAuthenticatedMode (dispatch) (false);
            }
        }  
    }
    
    const confirmationFieldJSX: JSX.Element = 
        <>
            <p>На почту отправлен код подтверждения</p>
            <input
                className="autorization_email"
                placeholder="Введите код подтверждения"
                required
                type="text"
                name="confirmCode" 
                id="confirmCodeInput"
            />
            <button className="autorization_button"
                onClick={() => handlerConfirmation()}>
                    Подтвердить
            </button>
        </>

    const regitrationFieldJSX: JSX.Element = 
        <>
            <input
                className="autorization_email"
                placeholder="Подтвердите пароль"
                required
                type="text"
                name="passwordDouble" 
                id="passwordDoubleInput"
            />

            <p>Правила пользования площадкой</p>
        </>
    
    const regitrationButtonJSX: JSX.Element = 
        <>
            <button className="registration_button"
                onClick={() => SetRegistrationMode(!registrationMode)}>
                    Регистрация
            </button>
        </>

    const loginJSX: JSX.Element = 
        <>
            <form className="autorization_box" onSubmit={handlerAutorization} >
                <h3 className="autorization_header">Авторизация</h3>
      
                <input
                    className="autorization_nic"
                    placeholder="nic"
                    required
                    type="text"
                    name="nic" 
                    maxLength={16}
                    id="nicInput"
                />

                <input
                    className="autorization_email"
                    placeholder="Ваш e-mail"
                    required
                    type="text"
                    name="email" 
                    id="emailInput"
                />

                <input
                    className="password"
                    placeholder="Пароль"
                    required
                    type="text"
                    name="password" 
                    id="passwordInput"
                />

                {(registrationMode) ? regitrationFieldJSX : <></>}

                <input 
                    type="submit" 
                    className="autorization_button"
                    name="submit_btn" 
                    value={ (registrationMode) ? "Зарегистрироваться" : "Войти"}
                />   
            </form>
            { (registrationMode) ? <></> : regitrationButtonJSX }

        </>

    return (
        <>      
        <div className="login_page">
            <button className="goback_button" onClick={handlerGoBack} >Назад</button>
            {(confirmationMode) ? confirmationFieldJSX : loginJSX }
        </div>
        </>
    );
}