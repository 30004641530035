import { SET_DEBUG_MODE } from "./actions";

const initialState = {
  debugMode: 0,
}

type DebugMode = {
    type: string,
    payload: number,
}

const debugModeReducer = (state = initialState, action: DebugMode) => {
  switch (action.type) {
    case SET_DEBUG_MODE:
      return {
        ...state,
        debugMode: action.payload
      }
    default:
      return state;
  }
};

export default debugModeReducer;
