import { useState } from "react";
import { Card, Photo } from "./HomePage";
import TelephoneNumberHiden from "./TelephoneNumberHiden";
import { useSelector } from "react-redux";

interface PetFullCardPageProps {
    petCard: Card;
}

export default function PetFullCardPage(props:PetFullCardPageProps): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);
    
    const buttonFavoriteJSX:JSX.Element =
        <>
            <div><button className="favorite_button">Поместить в избранное</button></div>
        </>
    
    const buttonWriteMessageJSX:JSX.Element =
    <>
        <div><button className="write_message_button">Написать сообщение</button></div>
    </>
        
    const [activePhotoID, SetactivePhotoID] = useState(0);

    const photoBar = props.petCard.photos.map((item:Photo) =>
        <div key={item.id} 
                onClick={() => SetactivePhotoID(item.id)} 
                className={(item.id===activePhotoID) ? "image_bar_item_active" : "image_bar_item"}>
            <img alt={item.alt} src={item.src} className="item_bar_photo" />
        </div>
    );

    return (
        <>
            <div className="full_card_item">
                <div className="full_card_item_image">
                    <img alt={props.petCard.photos[activePhotoID].alt} src={props.petCard.photos[activePhotoID].src} className="full_photo"/>
                    {
                        (props.petCard.photos.length>1) ? 
                            <div className="image_bar">
                                {photoBar}
                            </div> :
                            <></>
                    }
                </div>
                <div className="full_card_item_text">
                    <div>{props.petCard.name}</div>
                    <div>{props.petCard.breed}</div>
                    <div>{props.petCard.dob}</div>
                    <div>{props.petCard.price}</div>
                    <div>{props.petCard.description}</div>
                    {(userIsAuthenticated) ? buttonFavoriteJSX : <></>}
                    {(userIsAuthenticated) ? buttonWriteMessageJSX : <></>}
                    <TelephoneNumberHiden telNum={props.petCard.telNumber} 
                        telNumHiden={"+Х-ХXX-XXX-XX-XX"} 
                        className={"tel_number_hiden"} />
                </div>
            </div>
        </>
    );
}