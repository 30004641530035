import { Advert } from "./HomePage";

interface AdvertiserCardProps {
    advertiserCard: Advert;
}

export default function Advertiser(props:AdvertiserCardProps): JSX.Element {
    return (
        <>
            <div className="advertiser_item">
                {props.advertiserCard.name}
            </div>
        </>
    );
}