interface ConditionProps {
    text: string;
    status: boolean
}

export default function DebugInvitationEntryPage(props: ConditionProps): JSX.Element {

    return (
        <>
            <div className={(props.status) ? "condition_met" : "condition_not_met" }>
                    { props.text }
            </div>
        </>
    );
}