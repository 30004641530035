import { SET_AUTHENTICATED_MODE } from "./actions";

const initialState = {
  authenticatedMode: false,
}

type ActionAuthenticatedMode = {
    type: string,
    payload: boolean,
}

const authenticatedModeReducer = (state = initialState, action: ActionAuthenticatedMode) => {
  switch (action.type) {
    case SET_AUTHENTICATED_MODE:
      return {
        ...state,
        authenticatedMode: action.payload
      }
    default:
      return state;
  }
};

export default authenticatedModeReducer;
