import { useState } from "react";
import { Menu } from "./Login";

export default function Language(): JSX.Element {
    const [showLanguageMenu, SetShowLanguageMenu] = useState(false);
    const [activeLanguage, SetActiveLanguage] = useState(1);

    const languageMenuArr: Menu[] = [
        { id: 1, url: "", name: "Русский",},
        { id: 2, url: "", name: "Английский",},
        { id: 3, url: "", name: "Немецкий",},
        { id: 4, url: "", name: "Французский",},
        { id: 5, url: "", name: "Испанский",},
        { id: 6, url: "", name: "Итальянский",},
        { id: 7, url: "", name: "Китайский",},
        { id: 8, url: "", name: "Корейский",},
      ];

    const handlerClickLanguageMenuItem = (id: number) => {
        SetActiveLanguage(id);
        SetShowLanguageMenu(false);
    }
    
    const languageMenuItemJSX = languageMenuArr.map((item: Menu) =>
        <div className="login_menu_item">
            <div key={item.id} 
                className="menu_item" 
                onClick={() => handlerClickLanguageMenuItem(item.id)}>
            
                {item.name}

            </div>
        </div>
    );

    const languageButtonJSX: JSX.Element =
        <>
            <div>
                <button className="login_button">
                    { languageMenuArr[activeLanguage-1].name }
                </button>
            </div>
        </>

    const languageMenuJSX: JSX.Element =
        <>
            <div className="language_menu">
                {languageMenuItemJSX}
            </div>
        </>

    return (
        <>      
        <div className="language" onClick={() => SetShowLanguageMenu(!showLanguageMenu)}>
            { (showLanguageMenu) ? languageMenuJSX : languageButtonJSX }
        </div>
        </>
    );
}