import { useSelector } from "react-redux";
import { Card, Photo } from "../HomePage";
import { useState } from "react";

interface AnnouncementCard {
    announcementCard: Card;
}

export default function NurseryEdit(props:AnnouncementCard): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);
    const [activePhotoID, SetactivePhotoID] = useState(0);
    
    const photoBar = props.announcementCard.photos.map((item:Photo) =>
        <div key={item.id} 
                onClick={() => SetactivePhotoID(item.id)} 
                className={(item.id===activePhotoID) ? "image_bar_item_active" : "image_bar_item"}>
            <img alt={item.alt} src={item.src} className="item_bar_photo" />
        </div>
    );

    const buttonSaveJSX:JSX.Element =
        <>
            <div><button className="favorite_button">Сохранить</button></div>
        </>
    
    const buttonCancelJSX:JSX.Element =
        <>
            <div><button className="write_message_button">Отменить</button></div>
        </>

    return (
        <>
            <div className="full_card_item">
                <div className="full_card_item_image">
                    <img alt={props.announcementCard.photos[activePhotoID].alt} src={props.announcementCard.photos[activePhotoID].src} className="full_photo"/>
                    {
                        (props.announcementCard.photos.length>1) ? 
                            <div className="image_bar">
                                {photoBar}
                            </div> :
                            <></>
                    }
                </div>
                <div className="full_card_item_text">
                    <div>{props.announcementCard.name}</div>
                    <div>{props.announcementCard.breed}</div>
                    <div>{props.announcementCard.dob}</div>
                    <div>{props.announcementCard.price}</div>
                    <div>{props.announcementCard.description}</div>
                    {buttonSaveJSX}
                    {buttonCancelJSX}
                </div>
            </div>

        </>
    );
}