import { MessageTechSupport, TechSupportContact } from "../personal/TechSupport";


export const ticketList:Array<TechSupportContact> = [
    {
        id: 1,
        ticket: "1",
        nicName: "hhh",
        datetime: "2024-07-25 18:12",
        subject: "В списке пород отсутствует Корниш рекс",
        status:"Закрыта",
        text: "В списке пород отсутствует Корниш рекс, прошу добавить",
        files: ["photo/ph01.jpeg", "photo/ph03.jpeg", ],
    },
    {
        id:2, 
        ticket: "2",
        nicName: "hhh",
        datetime: "2024-07-27 15:10",
        subject: "Объявление не сохраняется",
        status:"Открыта",
        text: "Нажимаю кнопку Сохранить, проверяю, а оно не сохранилось",
        files: ["photo/ph06.jpeg", "photo/ph20.jpeg", ],
    }
]

export const messageTechSupport:Array<MessageTechSupport> = [
    {
        id: 1,
        techSupportContactID: 1,
        isTransmit: false,
        text: "Добавлено",
    },
    {
        id: 2,
        techSupportContactID: 1,
        isTransmit: true,
        text: "Благодарю",
    },
    {
        id: 3,
        techSupportContactID: 1,
        isTransmit: false,
        text: "Хорошего вечера",
    },
]