import { useDispatch, useSelector } from "react-redux";
import changeDebugMode from "../../redux/changeDebugMode";
import { useState } from "react";
import changeDebugUser from "../../redux/changeDebugUser";
import { DebugUser } from "../../redux/debugUserReduser";


export default function ConfirmPage(): JSX.Element {
    const dispatch = useDispatch();
    const { debugMode } = useSelector((state: any) => state.debugMode);
    const { debUserLogin, debUserNewPassword, debUserIsAuthenticated } = useSelector((state: any) => state.debugUser);
    
    const [showStatusRequest, SetShowStatusRequest] = useState(false);
    const [[success, message], SetStatusRequest] = useState([true, ""]);

    const handlerConfirm = () => {
        const debUser: DebugUser = {
            debUserLogin: "",
            debUserNewPassword: "",
            debUserIsAuthenticated: true
        }
        changeDebugUser (dispatch) (debUser);

        let mode = 0;
        if (debugMode === 4) { mode = 3}
        changeDebugMode (dispatch) (mode);
    }

    const handlerGoBack = () => {
        let mode = 1;
        if (debugMode === 4) { mode = 2}
        changeDebugMode (dispatch) (mode);
    }

    const handlerSend = async (event: any): Promise<void> => {
        event.preventDefault();
    
        let request = {};
        let url = "";

        if (debugMode === 4) {
            request = {
                "login": debUserLogin,
                "confirmation_code": event.target.confirmCodeInput.value,
              };
            url = "/deb_authorization/confirmation_authorization/"
        } else {
            request = {
                "login": debUserLogin,
                "confirmation_code": event.target.confirmCodeInput.value,
                "new_password": debUserNewPassword,
              };
            url = "/deb_authorization/confirmation_change_password/"
        } 
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(request),
        };
    
        fetch('https://mitrapro.tech' + url, requestOptions)
          .then((response) => {
            event.target.confirmCodeInput.value = "";
            return response.json();
          })
          .then((data) => {
            /*console.log(data);*/
            SetStatusRequest([data["success"], data["message"]]);
            if (data["success"]) {
                handlerConfirm();
            }
            SetShowStatusRequest(true);
          })
          .catch(() => {
            SetStatusRequest([false, "Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет."]);
          });
    }

    return (
        <>
            <button className="goback_button" onClick={handlerGoBack} >Назад</button>
            <div className="App">
                <form className="debug_login_box" onSubmit={handlerSend} >
                    <h3 className="">Подтвердите.</h3>
                    <p>
                        На email выслан код подтверждения.
                    </p>
                    <div className="debug_login_field_box">
                        <input
                            className="debug_login_field"
                            placeholder="Введите код подтверждения"
                            required
                            type="text"
                            name="confirmCode" 
                            maxLength={32}
                            id="confirmCodeInput"
                        />
                    </div>
                        
                    <div className="debug_login_field_box">
                        <input 
                            type="submit" 
                            className="debug_login_field"
                            name="submit_btn" 
                            value="Войти" 
                        />
                    </div>
                </form>
                { (showStatusRequest) ? message : "" }
            </div>
        </>
    );
}