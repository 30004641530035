import { User } from "../AppDev";
import { SET_DEBUG_USER } from "./actions";

const initialState = {
  debUserLogin: "",
  debUserNewPassword: "",
  debUserIsAuthenticated: false,
};

export type DebugUser = {
  debUserLogin: string,
  debUserNewPassword: string,
  debUserIsAuthenticated: boolean
};

type ActionDebUser = {
  type: string,
  payload: DebugUser,
};

const userReducer = (state = initialState, action: ActionDebUser) => {
  switch (action.type) {
    case SET_DEBUG_USER:
      return {
        ...state,
        debUserLogin: action.payload.debUserLogin,
        debUserNewPassword: action.payload.debUserNewPassword,
        debUserIsAuthenticated: action.payload.debUserIsAuthenticated
      }
    default:
      return state;
  }
};

export default userReducer
