import { Nursery } from "../personal/MyNursery";

export const nurseryArr:Array<Nursery> = [
    {
        id:1, 
        section: 1, 
        logoNursery:"", 
        name: "Big Eared Miracle",
        federation:"WCF",
        logoFrederation: "",
        breeder: "Shikunova Kristina",
        adress: "bulvar Klary Luchko 16/17 Krasnodar, 350089",
        country: "Russia",
        club: "Alisa Best, RU-0165",
        clubURL: "",
        registrationDate: "10.03.2015",
        registrationNumber: "7573-2015",
        validDate: "",
    },
    {
        id:2, 
        section: 1, 
        logoNursery:"", 
        name: "FOREST-MIRACLE",
        federation:"ICU",
        logoFrederation: "",
        breeder: "Shikunova Kristina",
        adress: "bulvar Klary Luchko 16/17 Krasnodar, 350089",
        country: "Russia",
        club: "Persona Cat",
        clubURL: "",
        registrationDate: "16.12.2016",
        registrationNumber: "ICU.0673.RU",
        validDate: "16.12.2026",
    }
]