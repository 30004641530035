import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppDev from './AppDev';
import { Provider } from 'react-redux';
import configureStore from './redux/Store';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const dev = false;

const store = configureStore();

root.render(
  <Provider store={store} >
    <BrowserRouter >
  
      { dev ? <AppDev /> : <App />}
  
    </BrowserRouter>
  </Provider>
);


// Выведение типов `RootState` и `AppDispatch` из хранилища
export type RootState = ReturnType<typeof store.getState>;
// Выведенные типы: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type StoreType = typeof store;


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);


/*
<React.StrictMode>
</React.StrictMode>
*/