import { useState } from "react";
import { useSelector } from "react-redux";

interface TelNumProps {
    telNum: string;
    telNumHiden: string;
    className: string;
}
export default function TelephoneNumberHiden(props:TelNumProps): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);
    
    const [showTelNumber, SetShowTelNumber] = useState(false);
    
    return (
        <>
            <div className={props.className} onClick={() => SetShowTelNumber(!showTelNumber)}>
                {(showTelNumber && userIsAuthenticated) ? props.telNum : props.telNumHiden}
            </div>
        </>
    );
}