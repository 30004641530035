import Content from "./Content";
import MainMenu from "./MainMenu";
import LoginPage from "./LoginPage";
import { useSelector } from "react-redux";

export default function ContentPage(): JSX.Element {
    const { authenticatedMode } = useSelector((state: any) => state.authenticatedMode);

    const modeViewPage: JSX.Element =
    <>
      <div className='main_menu'>
        <MainMenu />
      </div>
      <div className='content'>
        <Content />
      </div>
    </>

    return (
        <>      
            { (authenticatedMode) ? <LoginPage /> : modeViewPage }
        </>
    );
}