import { useSelector } from "react-redux";
import { Card } from "./HomePage";

interface PetShortCardProps {
    petCard: Card;
    isFavorite: boolean;
}

export default function PetShortCard(props: PetShortCardProps): JSX.Element {
    const { userIsAuthenticated } = useSelector((state:any) => state.user);
    
    const buttonFavoriteJSX: JSX.Element =
        <>
            <div><button className="favorite_button">Поместить в избранное</button></div>
        </>
    return (
        <>
            <div className="card_item">
                <div className="card_item_image">
                    <img alt={props.petCard.photos[0].alt} src={props.petCard.photos[0].src} className="photo"/>
                </div>
                <div className="card_item_text">
                    <div>{props.petCard.name}</div>
                    <div>{props.petCard.breed}</div>
                    <div>{props.petCard.dob}</div>
                    <div>{props.petCard.price}</div>
                    {(userIsAuthenticated && props.isFavorite) ? buttonFavoriteJSX : <></>}
                </div>
            </div>
        </>
    );
}