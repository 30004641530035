import { User } from "../AppDev";
import { SET_USER_LOGIN, SET_USER_LOGOUT } from "./actions";

const initialState: User = {
  userID: 0,
  userNic: "",
  userIsAuthenticated: false,
  userToken: "",
};

type ActionUser = {
  type: string,
  payload: User,
}

const userReducer = (state = initialState, action: ActionUser) => {
  switch (action.type) {
    case SET_USER_LOGIN:
      return {
        ...state,
        userID: action.payload.userID,
        userNic: action.payload.userNic,
        userIsAuthenticated: action.payload.userIsAuthenticated,
        userToken: action.payload.userToken,
      }
    case SET_USER_LOGOUT:
      return {
        ...state,
        userID: 0,
        userNic: "",
        userIsAuthenticated: false,
        userToken: "",
      }
    default:
      return state;
  }
};


export default userReducer
