import { useDispatch, useSelector } from "react-redux";
import changeDebugMode from "../../redux/changeDebugMode";
import { useState } from "react";
import { DebugUser } from "../../redux/debugUserReduser";
import changeDebugUser from "../../redux/changeDebugUser";

export default function DebugEntryPage(): JSX.Element {
    const dispatch = useDispatch();

    const handlerSelectDebugMode = (mode: number) => {
        changeDebugMode (dispatch) (mode);
      }
    
    const [showStatusRequest, SetShowStatusRequest] = useState(false);
    const [[success, message], SetStatusRequest] = useState([true, ""]);
    const [showPassword, SetShowPassword] = useState(false);
      
    const handlerSend = async (event: any): Promise<void> => {
        event.preventDefault()
    
        const request = {
          "login": event.target.nicInput.value,
          "password": event.target.passwordInput.value,
          "action": "LOGIN",
          };

        const debUser: DebugUser = {
          debUserLogin: event.target.nicInput.value,
          debUserNewPassword: "",
          debUserIsAuthenticated: false
        }
    
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(request),
          };
    
        fetch('https://mitrapro.tech/deb_authorization/authorization/', requestOptions)
          .then((response) => {
            event.target.nicInput.value = "";
            event.target.passwordInput.value = "";
            return response.json();
          })
          .then((data) => {
            /*console.log(data);*/
            SetStatusRequest([data["success"], data["message"]]);
            if (data["success"]) {
              changeDebugUser (dispatch) (debUser);
              handlerSelectDebugMode(4);
            }
            SetShowStatusRequest(true);
          })
          .catch(() => {
            SetStatusRequest([false, "Запрос не прошёл. Сервер не отвечает. Проверьте соединение с Интернет."]);
          });
    }
    
    const loginFormJSX: JSX.Element =
        <>
          <div className="App">
            <form className="debug_login_box" onSubmit={handlerSend} >
              <h3 className="">Вход только для сотрудников.</h3>
                
              <div className="debug_login_field_box">
                <label className="label_fld" htmlFor="nicInput">Логин:</label>
                <input
                  onInput={() => SetShowStatusRequest(false)}
                  className="debug_login_field"
                  placeholder="Логин"
                  required
                  type="text"
                  name="nic" 
                  maxLength={32}
                  id="nicInput"
                />
              </div>
    
              <div className="debug_login_field_box">
                <label className="label_fld" htmlFor="passwordInput">Пароль:</label>
                <input
                  onInput={() => SetShowStatusRequest(false)}
                  className="debug_login_field"
                  placeholder="Password"
                  required
                  type={(showPassword) ? "text" : "password" }
                  name="Password" 
                  id="passwordInput"
                  maxLength={32}
                />
              </div>
            
              <div className="debug_login_field_box">
                <input 
                  type="submit" 
                  className="debug_login_field"
                  name="submit_btn" 
                  value="Войти" 
                />
              </div>  
            </form>
            <button onClick={() => SetShowPassword(!showPassword)}>
              {(showPassword) ? "Скрыть пароль" : "Показать пароль" }
            </button>
          </div>
        </>
    
    const handlerGoBack = () => { 
        handlerSelectDebugMode(0);
    }

    return (
        <>
            <button className="goback_button" onClick={handlerGoBack} >Назад</button>
            { loginFormJSX }
            { (showStatusRequest) ? message : "" }
        </>
      );
    }
