import { useState } from "react";
import { cardList } from "../DebugData/DebugCardList";
import { Card } from "../HomePage";
import PetShortCard from "../PetShortCard";
import AnnouncementEdit from "./AnnouncementEdit";

export default function MyAnnouncements(): JSX.Element {
    function getAnnouncementCard(id: number): Card {
        let item:Card = { id: 0, name: "", breed: "", dob: "", price: "", photos: [{id: 0, src: "", alt: "",}], description: "", telNumber: ""};
        for (let i=0; i<cardList.length; i++) {
            if (id===cardList[i].id) {
                return cardList[i];
            }
        }
        return item;
    }

    const [showAnnouncementID, SetAnnouncementID] = useState(0);

    const handlerClickAnnouncement = (id:number) => {
        SetAnnouncementID(id);
    }

    const showMyAnnouncementsListJSX = cardList.map((item) =>
        <div key={item.id} onClick={() => handlerClickAnnouncement(item.id)}>
            <PetShortCard petCard={getAnnouncementCard(item.id)} isFavorite={false}/>
        </div>
    );

    return (
        <>      
            { (showAnnouncementID===0) ? <>{showMyAnnouncementsListJSX}</> :
                <>
                    <div className="go_back" onClick={() => SetAnnouncementID(0)}>
                        Назад
                    </div>
                    <AnnouncementEdit announcementCard={ getAnnouncementCard(showAnnouncementID) } />
                 </>
            }
        </>
    );
}